import axios from 'axios'

export default {
    login: (data) => axios.post('login', data),
    validate: (data) => axios.post('login/validate', data),
    validateAdmin: (data) => axios.post('login/validate-admin', data),

    validateEmail: (email) => axios.post('validate-email', {email: email}),
    getUser: (id) => axios.post('get-user', {id: id}),
    getAccount: (domain) => axios.post('get-account', {domain: domain}),
    activateAccount: (data) => axios.post('account/activate', data),
    recoveryUser: (data) => axios.post('users/send-recover-password-link', data),
    recoveryPassword: (data) => axios.post('users/recover-password', data),
    
    storeUser: (data) => axios.post('users/store', data),
    
    loginByCode : (data) => axios.post('login/by-code', data),
    forceLogin : (data) => axios.post('login/force', data),

    
}