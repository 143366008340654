import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheSupplierContainer = () => import('@/containers/TheSupplierContainer')

//
const TheAdminContainer = () => import('@/containers/TheAdminContainer');
const Accounts = () => import('@/pages/administrator/Accounts');

//Sections
const Landing = () => import('@/pages/web/Landing')
const Login = () => import('@/pages/application/login/Login')
const LoginLink = () => import('@/pages/application/login/LoginLink')
const NewAccountForm = () => import('@/pages/application/login/NewAccountForm')
const ActivationForm = () => import('@/pages/application/login/ActivationForm')
const ForgetForm = () => import('@/pages/application/login/ForgetForm')
const RecoveryForm = () => import('@/pages/application/login/RecoveryForm')

const Users = () => import('@/pages/application/Users')
const Roles = () => import('@/pages/application/Roles')

const Brands = () => import('@/pages/application/Brands')
const Cedis = () => import('@/pages/application/Cedis')
const Zones = () => import('@/pages/application/Zones')
const Warehouses = () => import('@/pages/application/Warehouses')
const Products = () => import('@/pages/application/Products')
const Customers = () => import('@/pages/application/Customers')
const Suppliers = () => import('@/pages/application/Suppliers')
const Managers = () => import('@/pages/application/Managers')
const Kams = () => import('@/pages/application/Kams')
const Providers = () => import('@/pages/application/Providers')
const Sales = () => import('@/pages/application/Sales')

const Awards = () => import('@/pages/application/Awards')
const AwardExchanges = () => import('@/pages/application/AwardExchanges')

const Challenges = () => import('@/pages/application/Challenges')
const ChallengeView = () => import('@/pages/application/ChallengeView')

const SupplierSales = () => import('@/pages/application/SupplierSales')
const CustomerSales = () => import('@/pages/application/CustomerSales')
const CedisSales = () => import('@/pages/application/CedisSales')

// Views
const Dashboard = () => import('@/pages/application/Dashboard')
const ChallengeDashboard = () => import('@/pages/application/ChallengeDashboard')
const SuppliersHome = () => import('@/pages/application/suppliers/SuppliersHome')
const SuppliersChallengeView = () => import('@/pages/application/suppliers/ChallengeView')
const InitialChallengeView = () => import('@/pages/application/suppliers/InitialChallenge')
Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/welcome',
      name: 'Bienvenido',
      component: TheSupplierContainer,
      children: [
        {
          path: '/',
          name: 'Inicio',
          component: SuppliersHome
        },
        {
          path: '/challenge/:challenge_id/view',
          name: 'Concursos',
          component: SuppliersChallengeView
        },
        {
          path: '/challenge/:challenge_id/initial',
          name: 'Concursos',
          component: InitialChallengeView
        },
      ]
    },
    {
      path: '/',
      redirect: '/home',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'home',
          name: 'Inicio',
          component: Dashboard
        },
        {
          path: '/users',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/roles',
          name: 'Roles',
          component: Roles
        },
        {
          path: '/brands',
          name: 'Proveedores (Marcas)',
          component: Brands
        },
        {
          path: '/cedis',
          name: 'CEDIS',
          component: Cedis
        },
        {
          path: '/cedis/:id/:name/sales',
          name: 'Ventas del Almacén',
          component: CedisSales
        },
        {
          path: '/zones',
          name: 'Zonas',
          component: Zones
        },
        {
          path: '/warehouses',
          name: 'Almacenes',
          component: Warehouses
        },
        {
          path: '/products',
          name: 'Productos',
          component: Products
        },
        {
          path: '/customers',
          name: 'Clientes',
          component: Customers
        },
        {
          path: '/customers/:id/:name/sales',
          name: 'Compras del Cliente',
          component: CustomerSales
        },
        {
          path: '/suppliers',
          name: 'Vendedores',
          component: Suppliers
        },
        {
          path: '/managers',
          name: 'Supervisores',
          component: Managers
        },
        {
          path: '/kams',
          name: 'Kams',
          component: Kams
        },
        {
          path: '/providers',
          name: 'Proveedores',
          component: Providers
        },
        {
          path: '/suppliers/:id/:name/sales',
          name: 'Ventas del Proveedor',
          component: SupplierSales
        },
        {
          path: '/suppliers/:id/:name/dashboard',
          name: 'Ventas del Proveedor',
          component: SuppliersHome
        },
        {
          path: '/sales',
          name: 'Registro de Ventas',
          component: Sales
        },
        {
          path: '/challenges/:type',
          name: 'Concursos',
          component: Challenges
        },
        {
          path: '/challenges',
          name: 'Concursos',
          component: Challenges
        },
        {
          path: '/challenges/:challenge_id/view',
          name: 'Concurso',
          component: ChallengeView
        },
        {
          path: '/challenges/:challenge_id/dashboard',
          name: 'Dasboard',
          component: ChallengeDashboard
        },
        {
          path: '/awards',
          name: 'Premios',
          component: Awards
        },
        {
          path: '/supplier-awards',
          name: 'Pedidos',
          component: AwardExchanges
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/:code/login-link',
      name: 'LoginLink',
      meta: {
        is_public: true,
      },
      component: LoginLink
    },
    {
      path: '/:domain/signup',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/signup',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/registro',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/account/active/:id/:code',
      name: 'Activa tu Cuenta',
      meta: {
        is_public: true,
      },
      component: ActivationForm
    },
    {
      path: '/forget',
      name: '¿Olvidaste tu Contraseña?',
      meta: {
        is_public: true,
      },
      component: ForgetForm
    },
    {
      path: '/account/recover-password/:id/:code',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: RecoveryForm
    },
    {
      path: '/',
      redirect: '/accounts',
      name: 'Inicio',
      component: TheAdminContainer,
      meta: {
        is_public: false,
        is_admin: true,
      },
      children: [
        {
          path: 'accounts',
          name: 'Cuentas',
          component: Accounts,
          meta: {
            is_public: false,
            is_admin: true,
          },
        },        
      ]
    },
    {
      path: '/bienvenido',
      name: 'Welcome',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '/',
          name: 'Landing Page',
          component: Landing
        }
      ]
    },
  ]
})